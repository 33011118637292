import { css, Global } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import * as React from 'react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      h4,
      h5,
      h6 {
        font-family: 'PFDINTextProMedium', Helvetica;
      }

      .leshen-table-wrapper {
        max-height: 450px;
        overflow-y: scroll;
      }

      li {
        svg {
          fill: #48d597;
        }
      }

      .top-bar {
        .top-nav {
          a {
            border-left: 1px solid #1d2329;
            &:first-of-type {
              border-left: none;
            }
          }
        }
      }
    `}
  />
))

export default GlobalCss
