module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.getcenturylink.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"removeTrailingSlashes":true,"analyticsId":"GTM-KJDDBG","brandTokens":["CTL","TND","DTV","CTLB"],"mapiBrandToken":"EMB","siteName":"getcenturylink","alternateName":"getcenturylink.com","siteURL":"https://www.getcenturylink.com","defaultTitleTemplate":"","defaultPhone":"8552041848","phoneSymbol":"-","defaultPromoCode":"99417","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10045793","vwoProjectID":"894940","cobra":{"sitePath":"getcenturylink.com","buttonLink":"/cart","buttonText":"Order Now","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"getcenturylink","short_name":"getcenturylink","start_url":"/","background_color":"#48D597","theme_color":"#48D597","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28a6ace9291e87b01d77ad9c07486849"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
